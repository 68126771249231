<template>
  <div>
    <b-card title="Liste des documents">
      <b-table
        :items="items"
        :fields="fields"
        responsive
        hover
        show-empty
        empty-text="Aucun document enrégistré"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template v-slot:cell(action)="data">
          <span
            class="cursor"
            @click="openUpdateModal(data.item)"
          >
            <b-icon
              class="text-warning"
              icon="pencil-square"
              font-scale="1.2"
            />
          </span>
          <span
            class="cursor"
            @click="openDeleteModal(data.item)"
          >
            <b-icon
              class="ml-1 text-danger"
              icon="trash"
              font-scale="1.2"
            />
          </span>
        </template>
      </b-table>
      <div
        v-if="!loaded && items.length > 0"
        class="mt-2"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="center"
        />
      </div>
    </b-card>
    <!-- Update Modal -->
    <b-modal
      v-if="showUpdateModal"
      v-model="showUpdateModal"
      hide-footer
      :title="`Modification`"
      centered
      :no-close-on-backdrop="process"
      :no-close-on-esc="process"
      :hide-header-close="process"
    >
      <div class="container">
        <b-row class="mb-4">
          <b-form @submit.prevent="tryToUpdate">
            <b-row>
              <b-col sm="12">
                <b-form-group label="Type de document">
                  <b-form-select
                    v-model="$v.currentDocument.docType.$model"
                    type="text"
                    plain
                    :options="docOptions"
                    :class="{'is-invalid':$v.currentDocument.docType.$error }"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Nom">
                  <b-form-input
                    v-model="$v.currentDocument.name.$model"
                    type="text"
                    :class="{'is-invalid':$v.currentDocument.name.$error }"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Description">
                  <b-form-textarea
                    v-model="$v.currentDocument.description.$model"
                    type="text"
                    :class="{'is-invalid':$v.currentDocument.description.$error }"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Etat">
                  <b-form-checkbox
                    v-model="currentDocument.status"
                    :value="true"
                    :unchecked-value="false"
                  >
                    <span v-if="currentDocument.status">Appliqué</span>
                    <span v-else>Non appliqué</span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-col sm="12">
              <div
                v-if="process"
                class="d-flex justify-content-center mt-2"
              >
                <b-spinner
                  variant="primary"
                  label="Loading..."
                />
              </div>
              <div class="mt-3">
                <div class="float-left">
                  <b-button
                    variant="secondary"
                    :disabled="process"
                    @click="reset"
                  >
                    Quitter
                  </b-button>
                </div>
                <div class="float-right">
                  <b-button
                    type="submit"
                    variant="outline-success"
                    :disabled="process"
                  >
                    Modifier
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-form>
        </b-row>
      </div>
    </b-modal>
    <!-- Delete Modal -->
    <b-modal
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      hide-footer
      :title="`Suppression`"
      centered
      :no-close-on-backdrop="deleteProcess"
      :no-close-on-esc="deleteProcess"
      :hide-header-close="deleteProcess"
    >
      <b-row class="mb-4">
        <b-col xxs="12">
          <b-form @submit.prevent="tryToDelete">
            <b-row>
              <b-col sm="12">
                <div class="text-justify">
                  Voulez-vous vraiment supprimer le document {{ getDocumentName(currentDocument.doc_type) }} ?
                </div>
              </b-col>
            </b-row>
            <b-col sm="12">
              <div
                v-if="deleteProcess"
                class="d-flex justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Loading..."
                />
              </div>
              <div class="mt-3">
                <div class="float-left">
                  <b-button
                    variant="secondary"
                    :disabled="deleteProcess"
                    @click="resetDelete"
                  >
                    Quitter
                  </b-button>
                </div>
                <div class="float-right">
                  <b-button
                    type="submit"
                    variant="outline-danger"
                    :disabled="deleteProcess"
                  >
                    Supprimer
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { required } = require('vuelidate/lib/validators')

export default {
  mixins: [validationMixin],
  data() {
    return {
      loaded: false,
      items: [],
      fields: [
        {
          key: 'doc_type',
          label: 'Document',
          formatter: val => this.getDocumentName(val),
        },
        { key: 'name', label: 'Nom' },
        { key: 'state', label: 'Etat', formatter: val => this.getState(val) },
        { key: 'action', label: 'Action' },
      ],
      totalItems: 0,
      currentPage: 1,
      perPage: 10,
      docOptions: [
        { value: null, text: 'Chosir' },
        { value: 'ID', text: "Pièce d'identité" },
        { value: 'FACE_ID', text: "Pièce d'identité + Selfie" },
      ],
      levelPartner: null,
      status: null,
      statusOptions: [
        { value: null, text: 'Chosir' },
        { value: true, text: 'Actif' },
        { value: false, text: 'Inactif' },
      ],
      showUpdateModal: false,
      showDeleteModal: false,
      currentDocument: {
        docType: null,
        id: null,
        name: null,
        status: null,
        description: null,
      },
    }
  },
  validations: {
    currentDocument: {
      name: {
        required,
      },
      status: {
        required,
      },
      description: {
        required,
      },
      docType: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'updateRequireDocumentSuccess',
      process: 'updateRequireDocumentProcess',
      error: 'updateRequireDocumentError',

      deleteSuccess: 'deleteRequireDocumentSuccess',
      deleteProcess: 'deleteRequireDocumentProcess',
      deleteError: 'deleteRequireDocumentError',
    }),
    page() {
      return this.currentPage - 1
    },
  },
  watch: {
    currentPage() {
      this.getAllDocuments()
    },
    perPage() {
      this.getAllDocuments()
    },
    success(val) {
      if (val) {
        this.reset()
        this.getAllDocuments()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Type de document KYC',
            text: 'Modifié avec succès.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Type de document KYC',
            text: 'Une erreur est survenue lors de la modification.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    deleteSuccess(val) {
      if (val) {
        this.resetDelete()
        this.getAllDocuments()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Type de document KYC',
            text: 'Supprimé avec succès.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    deleteError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Type de document KYC',
            text: 'Une erreur est survenue lors de la suppression.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  mounted() {
    this.getAllDocuments()
  },
  methods: {
    ...mapActions(['updateRequireDocument', 'deleteRequireDocument']),
    getDocumentName(item) {
      let name = null
      switch (item) {
        case 'ID':
          name = "PIECE D'IDENTITE"
          break
        case 'FACE_ID':
          name = 'SELFIE'
          break
        default:
          break
      }
      return name
    },
    getState(val) {
      let name = null
      if (Number(val) === 1) {
        name = 'ACTIF'
      } else {
        name = 'INACTIF'
      }
      return name
    },
    openUpdateModal(item) {
      this.currentDocument = {
        docType: item.doc_type,
        id: item.id,
        name: item.name,
        status: Number(item.state) === 1,
        description: item.description,
      }
      this.showUpdateModal = true
    },
    closeUpdateModal() {
      this.showUpdateModal = false
    },
    openDeleteModal(item) {
      this.currentDocument = item
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.showDeleteModal = false
    },

    async getAllDocuments() {
      this.loaded = true
      this.items = await this.$http
        .get('required-kyc-doc/all', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.totalItems = res.data.length
          this.loaded = false
          return res.data
        })
        .catch(() => {
          this.loaded = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Documents a fournir',
              text: 'Une erreur est survenue lors de la recuperation.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    reset() {
      this.closeUpdateModal()
      this.currentDocument.id = null
      this.currentDocument.level = null
      this.currentDocument.status = false
      this.currentDocument.company = null
      this.currentDocument.docType = null
      this.currentDocument.description = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    resetDelete() {
      this.closeDeleteModal()
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    tryToUpdate() {
      const body = {
        id: this.currentDocument.id,
        description: this.currentDocument.description,
        doc_type: this.currentDocument.docType,
        name: this.currentDocument.name,
        state: this.currentDocument.status,
      }
      this.updateRequireDocument(body)
    },
    tryToDelete() {
      this.deleteRequireDocument(this.currentDocument.id)
    },
  },
}
</script>

<style lang="scss" scoped>
 .cursor {
   cursor: pointer
 }
</style>

<style>

</style>
